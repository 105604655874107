export default function Copyright(){
        return (
    <div> 
        <div>
            <p>
                <div class="font-a34 font-centered">&copy; {new Date().getFullYear()} SandPiper Solutions Inc. All Rights Reserved.</div>
            </p>
        </div>     
    </div>
        );
    }
