import React, { Component } from "react";

// Component exports the About page structure to be rendered in the 'App' function
export class PageNotFound extends Component {
    render() {
        return (
            <>
                <h1>404 Page Not Found!</h1>
            </>
        );
    }
}

export default PageNotFound;
